:root {
}

@tailwind base;

html,
body,
#root {
  @apply h-full;
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** ================== common css ================== */
form .span-error {
  @apply text-xs text-rose-500;
}
.tooltip .info-tooltip {
  @apply btn-xs btn-square;
}

.btn {
  @apply h-8 px-4 capitalize min-h-0 border-none hover:border-none;
}
.btn-primary {
  @apply bg-cyfi-btn hover:bg-cyfi-hover;
}
.btn-outline {
  @apply border-slate-200 border-2 border-solid hover:border-none hover:bg-cyfi-hover;
}
.btn-ghost {
  @apply hover:bg-slate-300;
}
.btn img {
  @apply max-w-none;
}
.btn-avatar {
  @apply bg-transparent hover:bg-transparent;
}
.close-btn-ghost {
  @apply bg-transparent border-transparent border text-current;
}

.checkbox {
  @apply rounded-md checkbox-sm checked:bg-cyfi-btn;
}

.radio {
  @apply radio-sm checked:bg-white checked:border-cyfi-btn checked:border-[6px] focus:opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-cyfi-btn focus:outline-none;
}

.toggle {
  @apply toggle-sm checked:bg-cyfi-btn checked:border-cyfi-btn;
}

.table thead th {
  @apply text-[0.625rem];
}
.table th {
  @apply p-1.5 text-xs font-semibold text-center text-gray-500 last:sticky bg-white border-gray-300 border-solid border-b-2;
}
.table td {
  @apply p-1.5 text-xs text-center text-cyfi-gray-550 border-gray-300 border-solid border-b rounded-none;
}
.table-item-sign {
  @apply w-2.5 h-2.5 mx-2 rounded-full;
}
.table-overflow {
  @apply overflow-x-auto relative;
}

.navbar {
  @apply flex items-center p-0 w-full min-h-[44px];
}
.nav-hover {
  @apply h-8 flex items-center pl-3 -ml-px mb-2 cursor-pointer hover:rounded-lg hover:bg-cyfi-gray-350 hover:border-cyfi-gray-350;
}
.active {
  @apply rounded bg-cyfi-btn text-white hover:text-cyfi-hover hover:font-medium;
}
.nav-hover .btn-ghost,
.close-btn-ghost {
  @apply hover:border-cyfi-gray-350 hover:bg-cyfi-gray-350;
}

.tabs-boxed .tab-active {
  @apply bg-cyfi-btn hover:bg-cyfi-hover;
}

.card-body {
  @apply px-4 pt-4 pb-2;
}

.badge {
  @apply border-0 max-h-[18px] text-xs;
}

.toast {
  @apply p-0;
}

.menuHint.tooltip:before {
  @apply shadow text-left px-4 py-1 text-sm rounded-box;
}
.tooltip {
  --tooltip-tail: 6px;
  --tooltip-color: #597393;
}

.tooltip:before {
  @apply max-w-[17rem];
}
.menu li:not(.menu-title):not(:empty) > :not(ul):focus,
.menu li:not(.menu-title):not(:empty) > :not(ul):hover {
  @apply bg-cyfi-btn text-white left-14 font-semibold;
}
.menu li {
  @apply py-0.5;
}

.dropdown-right .dropdown-content {
  @apply -top-2.5;
}

.setting h2 {
  @apply pt-4 text-lg font-semibold text-cyan-900;
}
.divider {
  @apply my-2;
}
.cyfi-tab {
  @apply relative flex m-auto items-center flex-row justify-start w-full;
}
.cyfi-svg {
  filter: invert(0.2) sepia(80%) saturate(4) hue-rotate(160deg);
}
.active .cyfi-svg {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(9deg);
}
.select,
.input,
.file-input {
  @apply h-8 min-h-8;
}
.input,
.text-input {
  @apply input-bordered;
  &.input-eye {
    @apply border-0 focus:outline-offset-4 focus:outline-2 focus:-mr-8 focus:rounded-lg;
  }
}

.textarea {
  @apply -mt-0;
  &.textarea-clear {
    @apply border-0 focus:outline-offset-4 focus:outline-2 focus:-mr-8 focus:rounded-lg;
  }
}

::-moz-selection {
  @apply bg-cyfi-white-100;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: #333;
}

label {
  @apply pb-1;
}

/** ================== page css ================== */
.wifi-chart .recharts-label-list {
  @apply text-[10px];
}
.traffic-table .recharts-wrapper svg,
.client-table .recharts-wrapper svg {
  @apply w-4/5;
}
.collapse-content span.text-right {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* login animation */
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

/* login btn */
.login-btn {
  @apply w-full inline-flex flex-shrink-0 cursor-pointer select-none flex-wrap items-center justify-center text-center h-9 pl-4 pr-4 text-sm leading-none min-h-12 font-semibold no-underline border-opacity-100 bg-opacity-100 text-white animate-login-button-hover border-0 ease-all duration-[1250ms] ring-offset-0 ring-offset-white ring-opacity-50 drop-shadow-none outline-1 outline hover:border-0 hover:shadow-inner-1.5xl hover:drop-shadow-1.5sm hover:outline-offset-[15px] hover:outline-white/0 border-solid border-cyfi-white-50 rounded shadow-sm;
}

.tableFixHead {
  overflow: auto;
  height: 100%;
}
.tableFixHead thead tr {
  position: sticky;
  top: 0;
  z-index: 1;
}
.tableFixLeftCol {
  overflow: auto;
  width: 100%;
}
.tableFixLeftCol tbody td:first-child {
  position: sticky;
  left: 0;
}

/* hide ie edge input password eye*/
input[type='password']::-ms-reveal {
  display: none;
}

input[type='password']::-ms-clear {
  display: none;
}

input[type='password']::-o-clear {
  display: none;
}

/* toast filter */
.toastIcon-info {
  filter: invert(54%) sepia(46%) saturate(655%) hue-rotate(177deg) brightness(104%) contrast(96%);
}
.toastIcon-warning {
  filter: invert(82%) sepia(69%) saturate(2503%) hue-rotate(336deg) brightness(98%) contrast(98%);
}
.toastIcon-success {
  filter: invert(84%) sepia(99%) saturate(716%) hue-rotate(77deg) brightness(100%) contrast(66%);
}
.toastIcon-error {
  filter: invert(78%) sepia(24%) saturate(3603%) hue-rotate(307deg) brightness(91%) contrast(116%);
}
td:first-child,
th:first-child {
  position: sticky;
  left: 0; /* 首行永遠固定於左 */
  z-index: 1;
}
thead tr th {
  position: sticky;
  top: 0; /* 列首永遠固定於上 */
}
.table th::last-child {
  position: sticky;
}

.dropdown-content.dropdown-fixed {
  position: fixed;
  top: calc(70% + 5px);
}
.btn-group {
  &.btn-disabled,
  .btn-disabled:hover,
  .btn[disabled],
  .btn[disabled]:hover {
    @apply bg-transparent text-gray-300;
    filter: invert(72%) sepia(16%) saturate(194%) hue-rotate(179deg) brightness(87%) contrast(93%);
  }
}
.btn-disabled,
.btn-disabled:hover,
.btn[disabled],
.btn[disabled]:hover {
  @apply text-white;
}
